import { GkAction } from './index';

export const INDICATE_AREA = 'INDICATE_AREA';
/*
Indicate a entire property
 */
export const INDICATE_PROPERTY = 'INDICATE_PROPERTY';

/*
Indicate a teig on a property
 */
export const INDICATE_TEIG = 'INDICATE_TEIG';

/*
Indicate an owership-type for a property
 */
export const INDICATE_OWNERTYPE = 'INDICATE_OWNERTYPE';

export const ZOOM_TO_PROPERTY = 'ZOOM_TO_PROPERTY';
export const ZOOM_TO_OWNERTYPE = 'ZOOM_TO_OWNERTYPE';
export const ZOOM_TO_TEIG = 'ZOOM_TO_TEIG';

export function indicateAreaReducer(state: string, action: GkAction) {
  switch (action.type) {
    case INDICATE_AREA:
      return action.payload;
    default:
      return state;
  }
}
