import { inject, Injectable } from '@angular/core';
import { MapService } from '@services/map.service';
import { pointerMove } from 'ol/events/condition';
import Select from 'ol/interaction/Select';
import Translate from 'ol/interaction/Translate';
import { Observable, Subject } from 'rxjs';
import { first, map as rxMap } from 'rxjs/operators';

import { ApiConfig, PrintConfig } from '../_config';
import { MapTools, PrintTools } from '../_tools';
import { EndPointService } from './endpoint.service';

@Injectable()
export class PrintService {
  private _pdfIsReady: boolean;
  private endPointService = inject(EndPointService);
  private mapService = inject(MapService);
  private pdfLink: string;
  private printBox: any;
  private printLayout: string;
  private printMode: string;
  private printPaper: string;
  private printScale: number;
  private savePrint: boolean;
  private savingPdfControl: Subject<void>;
  private select: Select;
  private translate: Translate;

  set pdfIsReady(b: boolean) {
    this._pdfIsReady = b;
  }

  get pdfIsReady(): boolean {
    return this._pdfIsReady;
  }

  constructor() {
    this.printScale = PrintConfig.DEFAULT_SCALE;
    this.printLayout = PrintConfig.PORTRAIT;
    this.printPaper = PrintConfig.A4;
    this.printMode = PrintConfig.REGULAR;
    this.savingPdfControl = new Subject<void>();
  }

  /**
   * Makes a request to the print endpoint, getting the raw PDF data
   * @param  {string}          endPointUrl URL of the print endpoint
   * @param  {any}             printBody   Print request body
   * @return {Observable<any>}             Observable of the response
   *                                       from the print endpoint
   */
  private fetchMapData(endPointUrl: string, printBody: any): Observable<any> {
    this.savingPdfControl = new Subject<void>();
    return this.endPointService.postHttp(endPointUrl, printBody, '');
  }

  /**
   * getPrintBodyRegular
   * Parses the PrintProperty, and replaces the base fields the
   * appropriate values.
   * @param  {PrintProperty} printProperty PrintProperty object, containing the
   *                                       information that should replace the
   *                                       base field values.
   * @return {any}                         JSON-data to be sent as a request to
   *                                       the print endpoint for regularMode.
   */
  private getPrintBodyRegular(printProperty: PrintProperty): any {
    const printBody: any = {};
    printBody.isStandard = true;
    printBody.usersessionIdType = this.endPointService.sessionId;
    const mapfish = this.getmapfishBodyBaseCommon(printProperty);
    mapfish.attributes.type = printProperty.selectedGkType;
    mapfish.attributes.show_all = printProperty.showAll;
    mapfish.attributes.gardnr = PrintTools.getReadableHnr(printProperty.soektEiendom);
    mapfish.attributes.isLandbrukseiendom = PrintTools.isFarm(printProperty.soektEiendom);
    mapfish.attributes.lastVerificationTimestamp = PrintTools.getlastVerificationTime(printProperty.soektEiendom);
    mapfish.attributes.tilkn_grunneiendommer = PrintTools.getAssociatedPropertyAll(printProperty.soektEiendom);
    mapfish.attributes.properties = PrintTools.getAssociatedPropertyStatistic(printProperty.soektEiendom);
    printBody.mapfish = JSON.stringify(mapfish);
    return printBody;
  }

  private getSizeDefMapfish() {
    let size: any = {};
    const getSizeFromPanel = this.printMode + '_' + this.printPaper + '_' + this.printLayout;
    if (getSizeFromPanel === 'ADVANCED_A4_PORTRAIT') {
      size = PrintConfig.SIZE_ADVANCED_A4_PORTRAIT;
    } else if (getSizeFromPanel === 'ADVANCED_A4_LANDSCAPE') {
      size = PrintConfig.SIZE_ADVANCED_A4_LANDSCAPE;
    } else if (getSizeFromPanel === 'ADVANCED_A3_PORTRAIT') {
      size = PrintConfig.SIZE_ADVANCED_A3_PORTRAIT;
    } else if (getSizeFromPanel === 'ADVANCED_A3_LANDSCAPE') {
      size = PrintConfig.SIZE_ADVANCED_A3_LANDSCAPE;
    } else if (getSizeFromPanel === 'REGULAR_A4_PORTRAIT') {
      size = PrintConfig.SIZE_REGULAR_A4_PORTRAIT;
    } else if (getSizeFromPanel === 'REGULAR_A4_LANDSCAPE') {
      // we don't offer landsscape for regulary
      size = PrintConfig.SIZE_REGULAR_A4_PORTRAIT;
    } else if (getSizeFromPanel === 'REGULAR_A3_PORTRAIT') {
      size = PrintConfig.SIZE_REGULAR_A3_PORTRAIT;
    } else if (getSizeFromPanel === 'REGULAR_A3_LANDSCAPE') {
      // we don't offer landsscape for regulary
      size = PrintConfig.SIZE_REGULAR_A3_PORTRAIT;
    } else {
      size = PrintConfig.SIZE_ADVANCED_A4_PORTRAIT;
    }
    return size;
  }

  /**
   * Add the print box to the map
   * @param  {any  = null}    center    optional - custom center point of box
   * @return {boolean}                  whether or not the box was added to the map
   */
  addPrintBox(center: any = null): boolean {
    const code = this.mapService.getCode();
    if (center === null) {
      this.printBox = PrintTools.createPrintLayer(
        this.createBoxFromCenter(PrintTools.getCenterOfExtent(this.mapService.getViewExtent()), this.printScale),
        code,
      );
    } else {
      this.printBox = PrintTools.createPrintLayer(this.createBoxFromCenter(center, this.printScale), code);
    }

    this.select = new Select({
      condition: pointerMove,
      layers: [this.printBox],
      style: MapTools.getStyle(PrintConfig.PRINT_BOX_COLOR_FILL_ACTIVE, PrintConfig.PRINT_BOX_COLOR_LINE, 3),
    });

    this.translate = new Translate({
      features: this.select.getFeatures(),
    });

    this.mapService.addInteraction(this.select);
    this.mapService.addInteraction(this.translate);

    return this.mapService.addLayer('printBox', this.printBox);
  }

  /**
   * Cancel the saving of a print
   */
  cancelPrint(): void {
    this.savePrint = false;
    this.savingPdfControl.next();
    this.savingPdfControl.complete();
  }

  /**
   * Create a box layer from a center point and boxsize
   * @param  {any}        centerPoint   Center of box to be created
   *                                    this.mapService.getViewExtent()
   * @param  {number}     boxSize       Size of the box that should be created
   *                                    this.printScale
   * @return {Array<any>}               Coordinates (features) of the boundaries
   *                                    (corners) of the box
   */
  createBoxFromCenter(centerPoint: any, boxSize: number) {
    const size = this.getSizeDefMapfish();
    const map = this.mapService.getMap();
    const s = boxSize;
    const view = map.getView();
    const center = map.getPixelFromCoordinate(centerPoint);
    const resolution = view.getResolution();
    const w = ((((size.width / PrintConfig.DPI) * PrintConfig.MM_PER_INCHES) / 1000.0) * s) / resolution;
    const h = ((((size.height / PrintConfig.DPI) * PrintConfig.MM_PER_INCHES) / 1000.0) * s) / resolution;

    const minx = center[0] - w / 2;
    const miny = center[1] - h / 2;
    const maxx = center[0] + w / 2;
    const maxy = center[1] + h / 2;

    const UPPER_RIGHT = map.getCoordinateFromPixel([maxx, maxy]);
    const LOWER_RIGHT = map.getCoordinateFromPixel([maxx, miny]);
    const LOWER_LEFT = map.getCoordinateFromPixel([minx, miny]);
    const UPPER_LEFT = map.getCoordinateFromPixel([minx, maxy]);

    return [UPPER_RIGHT, LOWER_RIGHT, LOWER_LEFT, UPPER_LEFT];
  }

  getBBox() {
    return this.mapService.getLayer('printBox').getSource().getExtent();
  }

  getCenterPrintBox() {
    return PrintTools.getCenterOfExtent(this.getPrintBoxExtent());
  }

  /**
   * make a common basebody for mapfish-conf
   * @param  {PrintProperty} printProperty PrintProperty object, containing the
   *                                       information that should replace the
   *                                       base field values.
   * @return {object}                      object with jsonstructure for mapfish
   */
  getmapfishBodyBaseCommon(printProperty: PrintProperty): any {
    const mapfishBodyBase: any = { attributes: { map: {} } };
    mapfishBodyBase.outputFormat = 'pdf';
    mapfishBodyBase.layout = printProperty.printLayout;
    mapfishBodyBase.attributes.scale = printProperty.mapScale;
    mapfishBodyBase.attributes.aerial_image = printProperty.isAerial;
    mapfishBodyBase.attributes.map.scale = printProperty.mapScale;
    mapfishBodyBase.attributes.map.bbox = printProperty.bBox;
    mapfishBodyBase.attributes.map.dpi = PrintConfig.DPI;
    mapfishBodyBase.attributes.map.projection = this.mapService.getCode();
    mapfishBodyBase.attributes.map.layers = printProperty.layers;
    return mapfishBodyBase;
  }

  /**
   * getPrintBodyAdvanced
   * Parses the PrintProperty, and replaces the base fields the
   * appropriate values.
   * @param  {PrintProperty} printProperty PrintProperty object, containing the
   *                                       information that should replace the
   *                                       base field values.
   * @return {any}                         JSON-data to be sent as a request to
   *                                       the print endpoint for advancedMode.
   */
  getPrintBodyAdvanced(printProperty: PrintProperty): any {
    const printBody: any = {};
    printBody.isStandard = false;
    printBody.isMap = printProperty.isMap;
    printBody.isLegend = printProperty.isLegend;
    printBody.isStatistic = printProperty.isStatistic;

    printBody.isParcelLevel = printProperty.isParcelLevel;
    printBody.type = printProperty.selectedGkType;
    printBody.pageSize = printProperty.pageSize;
    printBody.pageOrientation = printProperty.pageOrientation;
    printBody.typeNavn = printProperty.typeName;
    printBody.propertiesAdded = printProperty.addedProperty;
    printBody.usersessionIdType = this.endPointService.sessionId;

    printBody.isLandbrukseiendom = PrintTools.isFarm(printProperty.soektEiendom);
    printBody.farm = PrintTools.getReadableHnr(printProperty.soektEiendom);
    printBody.verificationTimestamp = PrintTools.getlastVerificationTime(printProperty.soektEiendom);
    printBody.properties = PrintTools.getAssociatedPropertyStatistic(printProperty.soektEiendom);

    const mapfish = this.getmapfishBodyBaseCommon(printProperty);
    mapfish.attributes.typeNavn = printProperty.typeName;
    mapfish.attributes.title = printProperty.mapName;
    printBody.mapfish = JSON.stringify(mapfish);
    return printBody;
  }

  /**
   * Get the coordinates of the printBox corners
   * @return {Array<any>}  coordinates for each corner (4 corners)
   */
  getPrintBoxCoordinates(): any[] {
    const extent = this.getPrintBoxExtent();
    return PrintTools.convertExtentToCoordinates(extent);
  }

  /**
   * Get the extent of the printBox
   * @return {Array<any>}     extent of the printBox
   */
  getPrintBoxExtent(): any[] {
    return this.printBox.getSource().getExtent();
  }

  getSavingPdfControl(): Subject<void> {
    return this.savingPdfControl;
  }

  hidePrintBox(): void {
    const pBox = this.mapService.getLayer('printBox');
    if (pBox) {
      pBox.setVisible(false);
    }
  }

  /**
   * Check if the printbox is visible i the map.
   * return true if printbox is within view, false if printbox does not exist or is not inside view
   */
  isPrintBoxInViewExtent(): boolean {
    const pBox = this.mapService.getLayer('printBox');
    if (pBox === null) {
      return false;
    }
    return this.mapService.isLayerInViewExtent(pBox);
  }

  openPdf(): void {
    window.open(this.pdfLink, '_blank');
  }

  /**
   * Remove the printBox from the map
   * @return {boolean}  whether or not the box was removed from the map
   */
  removePrintBox(): boolean {
    this.mapService.removeInteraction(this.select);
    this.mapService.removeInteraction(this.translate);

    return this.mapService.removeLayer('printBox');
  }

  /**
   * Request the map data, parses it and saves it for printing.
   * @param  {string}              documentTitle Title of the saved document
   * @param  {PrintProperty}       printProperty PrintProperty object, containing
   *                                             the information needed to print
   * @return {Observable<boolean>}               Observable indicating that the
   *                                             request was successful
   */
  saveMapDataAsPDF(documentTitle: string, printProperty: PrintProperty): Observable<boolean> {
    this.savePrint = true;
    const printBody =
      this.printMode === PrintConfig.REGULAR
        ? this.getPrintBodyRegular(printProperty)
        : this.getPrintBodyAdvanced(printProperty);

    return this.fetchMapData(ApiConfig.printUrlBackend, printBody).pipe(
      rxMap(data => {
        const printData = data;
        if (this.savePrint === true && printData.status === 'OK') {
          this.pdfLink = ApiConfig.printPdfUrl + printData.ouputFile;
          this.savePrint = false;
          return true;
        }
        throw new Error('Utskrift feilet');
        // the following is cought by data and not by error in this.printService.saveMapDataAsPDF
        // return false;
      }),
      first(),
    );
  }

  /**
   * Set the print layout
   * @param {string} layout  new print layout
   */
  setPrintLayout(layout: string): void {
    this.printLayout = layout;
  }

  /**
   * Set the print mode
   * @param {string} mode   new print mode
   */
  setPrintMode(mode: string): void {
    this.printMode = mode;
  }

  /**
   * Set the print paper type
   * @param {string} paper  new paper type
   */
  setPrintPaper(paper: string): void {
    this.printPaper = paper;
  }

  /**
   * Set the print scale
   * @param {number} scale  new print scale
   */
  setPrintScale(scale: number): void {
    this.printScale = scale;
  }

  showPrintBox(): void {
    if (this.isPrintBoxInViewExtent()) {
      const pBox = this.mapService.getLayer('printBox');
      pBox.setVisible(true);
    } else {
      this.removePrintBox();
      this.addPrintBox();
    }
  }

  /**
   * Update/refresh the printBox
   * @param {boolean = false}     updateExistingPrintBox    - optional
   *                              whether or not this is a new box,
   *                              or if we want to update an existing printBox
   */
  updatePrintBox(updateExistingPrintBox = false): void {
    let center: any[];
    if (updateExistingPrintBox) {
      center = PrintTools.getCenterOfExtent(this.getPrintBoxExtent());
    }

    this.removePrintBox();

    if (updateExistingPrintBox === true) {
      this.addPrintBox(center);
    } else {
      this.addPrintBox();
    }
  }
}

/**
 * IPrintProperties specifies the arguments needed for printing to PDF
 */
export type IPrintProperties = {
  addedProperty: string;
  bBox: any;
  isAerial: boolean;
  isLegend: boolean;
  isMap: boolean;
  isParcelLevel: boolean;
  isStatistic: boolean;
  layers: any;
  mapName: string;
  mapScale: number;
  pageOrientation: string;
  pageSize: string;
  printLayout: string;
  selectedGkType: string;
  showAll: boolean;
  soektEiendom: any;
  typeName: string;
};

/**
 * PrintProperty class to hold the fields to be sent to the
 * print endpoint. This is to prevent large JSON-objects.
 * @param  {IPrintProperties} properties The object (JSON) passed must fulfill
 *                                       the IPrintProperties-interface
 */
export class PrintProperty {
  addedProperty: string;
  bBox: any;
  isAerial: boolean;
  isLegend: boolean;
  isMap: boolean;
  isParcelLevel: boolean;
  isStatistic: boolean;
  layers: any;
  mapName: string;
  mapScale: number;
  pageOrientation: string;
  pageSize: string;
  printLayout: string;
  selectedGkType: string;
  showAll: boolean;
  soektEiendom: any;
  typeName: string;

  constructor(properties: IPrintProperties) {
    this.addedProperty = properties.addedProperty;
    this.mapName = properties.mapName;
    this.mapScale = properties.mapScale;
    this.printLayout = properties.printLayout;
    this.selectedGkType = properties.selectedGkType;
    this.showAll = properties.showAll;
    this.pageSize = properties.pageSize;
    this.pageOrientation = properties.pageOrientation;
    this.isParcelLevel = properties.isParcelLevel;
    this.isMap = properties.isMap;
    this.isLegend = properties.isLegend;
    this.isStatistic = properties.isStatistic;
    this.typeName = properties.typeName;
    this.layers = properties.layers;
    this.bBox = properties.bBox;
    this.soektEiendom = properties.soektEiendom;
    this.isAerial = properties.isAerial;
  }
}
