import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Property } from '@models/property';

@Injectable()
export class ParamService {
  config = {
    regBnr: /^\d{1,4}$/,

    regFnr: /^\d{0,4}$/,

    regGnr: /^\d{1,5}$/,
    // Reg-ex for validating values
    regKom: /^\d{3,4}$/,
  };
  errorFormat = false;

  formatError = '';

  private addToFormatError(field) {
    if (this.formatError !== '') {
      this.formatError += ', ';
    }
    this.formatError += field;
  }

  checkRegex(input: string, regexIn: RegExp) {
    return this.validateRegex(input, regexIn);
  }

  parseUrl(params: Params, grunneiendom = false): Property[] {
    // parse the params (split on comma - ',')
    const komm = params['komm'].split(',');
    const gnr = params['gnr'].split(',');
    const bnr = params['bnr'].split(',');
    const fnr = params['fnr'].split(',');

    const properties: Property[] = [];

    // make sure gnr, bnr and fnr has equal amount of arguments
    if (!(gnr.length === bnr.length && bnr.length === fnr.length)) {
      this.addToFormatError('Mangler en eller flere parametre.');
      return null;
    }

    if (komm.length !== gnr.length && !grunneiendom) {
      this.addToFormatError('Mangler en eller flere parametre.');
      return null;
    }

    for (let i = 0; i < gnr.length; i++) {
      properties.push(
        new Property({
          bnr: bnr[i],
          fnr: fnr[i],
          gnr: gnr[i],
          komm: komm[grunneiendom ? 0 : i],
        }),
      );
    }

    // check that the properties has valid values
    if (!this.validProperties(properties)) {
      return null;
    }

    return properties;
  }

  validateRegex(value: string, regexIn: RegExp) {
    return regexIn.test(value);
  }

  /**
   * Validate the parameter komm, gnr, bnr and fnr.
   * @param  {string}              komm  Kommunenummer
   * @param  {string}              gnr   Gårdsnummer
   * @param  {string}              bnr   Bruksnummer
   * @param  {string}              fnr   Festenummer
   * @return {boolean}             true  if parameters validate, false otherwise.
   * If validation fails, the property formatError is set to the failing case.
   */
  validProperties(properties: Property[]): boolean {
    this.formatError = '';

    for (const property of properties) {
      try {
        if (property.komm.length === 3) {
          property.komm = '0' + property.komm;
        }

        if (!this.checkRegex(property.komm, this.config.regKom)) {
          this.addToFormatError('kommune');

          return false;
        }
      } catch {
        this.addToFormatError('kommune');
        return false;
      }

      try {
        if (!this.checkRegex(property.gnr, this.config.regGnr)) {
          this.addToFormatError('gårdsnummer');
          return false;
        }
      } catch {
        this.addToFormatError('gårdsnummer');
        return false;
      }

      try {
        if (!this.checkRegex(property.bnr, this.config.regBnr)) {
          this.addToFormatError('bruksnummer');
          return false;
        }
      } catch {
        this.addToFormatError('bruksnummer');
        return false;
      }

      try {
        if (!this.checkRegex(property.fnr, this.config.regFnr)) {
          this.addToFormatError('festenummer');
          return false;
        }
      } catch {
        this.addToFormatError('festenummer');
        return false;
      }
    }

    return true;
  }
}
