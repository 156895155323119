/**
 * Structure for saving data for farms
 *
 * get: get cached data
 * add: adde data to cache
 * inCache: does cache contain data for requested value?
 */
export class StatCache {
  private readonly _cache: any;

  constructor() {
    this._cache = {};
  }

  /**
   * Return the data saved for this farm and type of data
   * @param  {string}     type     The type of data to flush
   * @param  {string}     key
   *
   */
  private flush(type: string, key: string) {
    if (!Object.prototype.hasOwnProperty.call(this._cache, type) || this._cache[type].length === 0) {
      // No data in the cach for this maptype
      return {};
    }
    let i = -1;
    this._cache[type].forEach((item: any, index: number) => {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        // Update existing data
        i = index;
      }
    });
    if (i >= 0) {
      this._cache[type].splice(i, 1);
    }
    return undefined;
  }

  /**
   * Add a responseobject to the cache for the given state and farms
   * @param  {string}     mapState The type of data to add
   * @param  {Array<any>} farms    An object decribing the list of farms, for exammple:
   * [{"komm":"214","gnr":"30","bnr":"1","fnr":"0"},{"komm":"214","gnr":"35","bnr":"4","fnr":"0"}]
   * @param  {any}        data     A responseobject or any other type of data to save for this farm
   */
  add(mapState: string, farms: any[], data: any) {
    if (!Object.prototype.hasOwnProperty.call(this._cache, mapState)) {
      this._cache[mapState] = [];
    }
    const key = JSON.stringify(farms);
    this.flush(mapState, key);
    const cacheEntry = {};
    cacheEntry[key] = data;
    // Push data at the end of the array
    this._cache[mapState].push(cacheEntry);
  }

  /**
   * Return the data saved for this farm and type of data
   * @param  {string}     type     The type of data to add
   * @param  {Array<any>} farms    An object decribing the list of farms, for exammple:
   *   [{"komm":"214","gnr":"30","bnr":"1","fnr":"0"},{"komm":"214","gnr":"35","bnr":"4","fnr":"0"}]
   * @return                       The data saved for the requested farms and type
   */
  get(type: string, farms: any) {
    if (!Object.prototype.hasOwnProperty.call(this._cache, type) || this._cache[type].length === 0) {
      // No data in the cach for this maptype
      return {};
    }
    const targetAsString = JSON.stringify(farms);
    const cachedFarms = this._cache[type];
    for (const f of cachedFarms) {
      if (Object.prototype.hasOwnProperty.call(f, targetAsString)) {
        return f[targetAsString];
      }
    }
    return {};
  }

  /**
   * Check if cach contains data for the requested farms and type
   * @param  {string}   type of data to check for, ie. 'ar5kl7'
   * @param  {any[]}    farms array of farms, exammple:
   * [{"komm":"214","gnr":"30","bnr":"1","fnr":"0"},{"komm":"214","gnr":"35","bnr":"4","fnr":"0"}]
   * @return {boolean} False if farms does not have data in cache, true otherwise
   */
  inCache(type: string, farms: any[]): boolean {
    if (!Object.prototype.hasOwnProperty.call(this._cache, type) || this._cache[type].length === 0) {
      // No data in the cach for this maptype
      return false;
    }
    const newAsString = JSON.stringify(farms);
    const cachedFarms = this._cache[type];
    for (const f of cachedFarms) {
      if (Object.prototype.hasOwnProperty.call(f, newAsString)) {
        return f[newAsString] !== null;
      }
    }
    return false;
  }
}
