import { GkAction } from './index';

export const BG_UPDATE = 'BG_UPDATE';

const DEFAULT = 'graatoner';

export function backgroundReducer(state: string = DEFAULT, action: GkAction) {
  switch (action.type) {
    case BG_UPDATE:
      return action.payload;
    default:
      return state;
  }
}
