import { inject, Injectable } from '@angular/core';
import { ApiConfig } from '@config/api.config';
import { PropertyState, SoektEiendom } from '@models/property';
import { select, Store } from '@ngrx/store';
import { StoreStates } from '@reducers/store-states';
import { EndPointService } from '@services/endpoint.service';
import { PropertyService } from '@services/property.service';
import { StatCache } from '@tools/misc/statCache';
import BaseLayer from 'ol/layer/Base';
import VectorLayer from 'ol/layer/Vector';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AreaStatisticsService {
  readonly #endPointService = inject(EndPointService);
  readonly #propertyService = inject(PropertyService);
  readonly #store = inject<Store<StoreStates>>(Store);

  private _addedProperties;
  private _gkType: string;
  private _showAll: boolean;
  /**
   * Should have the structure
   * {
   *  'ar5kl7': {
   *   farms: gardliste
   *   geodata:
   *  },
   *  'ar513kl': ....
   * }
   * @type {any}
   */
  private _statisticsCache: StatCache;

  set gkType(type: string) {
    this._gkType = type;
  }

  get gkType(): string {
    return this._gkType;
  }

  set showAll(b: boolean) {
    this._showAll = b;
  }

  get showAll() {
    return this._showAll;
  }

  constructor() {
    this.emptyCache();

    this.#store
      .pipe(select('addedProperties'))
      .pipe()
      .subscribe({
        next: props => {
          this._addedProperties = props;
        },
      });
  }

  private _isParcelVisible(parcel: object, layer: BaseLayer, hidden: string[]): boolean {
    if (!layer.getVisible()) {
      return false;
    }

    if (layer instanceof VectorLayer) {
      const features = layer.getSource().getFeatures();
      const gId = parcel['municipality_nr'] + '-' + parcel['gnr'] + '/' + parcel['bnr'] + '/' + parcel['fnr'];
      if (hidden.includes(gId)) {
        return false;
      }

      const parcelFeature = features.find(f => f.get('id') === gId);
      if (parcelFeature) {
        if (parcelFeature.get('show') === false) {
          return false;
        }
        if (parcelFeature.getVisible() === false) {
          return false;
        }
      }
    }
    return true;
  }

  private getPropertyInputParameters(gid: string): any {
    return this.getPropertyInputParametersAndExpand(gid).inputParams;
  }

  // prepare inputParams to send it to backend (for a specific property)
  private getPropertyInputParametersAndExpand(id: string): any {
    const propertyIdType = [];
    const expand: any = {};
    const p = this.#propertyService.getEiendom(id);
    expand[p.hovednummer + '_defaultOwner'] = true;
    expand[p.hovednummer + '_specialOwner'] = true;
    // add it to inputParams to get statistics, whether isFarm or not
    const gList = p.grunneiendomListe;
    const gll = gList.length;
    for (let gli = 0; gli < gll; ++gli) {
      const grunnEiendom = gList[gli];
      const gid = grunnEiendom.gid.split('-');
      propertyIdType.push({
        bnr: gid[2],
        fnr: gid[3],
        gnr: gid[1],
        komm: gid[0],
      });
    }
    const inputParams = {
      propertyIdType: propertyIdType,
    };
    return { expand: expand, inputParams: inputParams };
  }

  emptyCache(): void {
    this._statisticsCache = new StatCache();
    return;
  }

  // getInputParameters() {
  //   // prepare inputParams to send it to backend
  //   const inputParams = {
  //     propertyIdType: [],
  //   };
  //
  //   // const sList = new  Array<SoektEiendom>();
  //   // if (this.propertyService.getSoektEiendom()) {
  //   //   sList.push(this.propertyService.getSoektEiendom());
  //   // }
  //   // sList.concat(this.propertyService.getAddedEiendomsListe());
  //
  //   const soektEiendom = this.propertyService.getSoektEiendom();
  //   if (!soektEiendom) {
  //     return;
  //   }
  //   const sList = [soektEiendom].concat(this.propertyService.getAddedEiendomsListe());
  //   const sll = sList.length;
  //   for (let sli = 0; sli < sll; ++sli) {
  //     const eiendom = sList[sli];
  //
  //     // add it to inputParams to get statistics, whether isFarm or not
  //     const gList = eiendom.grunneiendomListe;
  //     const gll = gList.length;
  //     for (let gli = 0; gli < gll; ++gli) {
  //       const grunnEiendom = gList[gli];
  //       const gid = grunnEiendom.gid.split('-');
  //       inputParams.propertyIdType.push({
  //         bnr: gid[2],
  //         fnr: gid[3],
  //         gnr: gid[1],
  //         komm: gid[0],
  //       });
  //     }
  //   }
  //   return inputParams;
  // }

  generateFarmParameters(sList: SoektEiendom[]): any {
    // prepare inputParams to send it to backend
    const inputParams = { propertyIdType: [] };
    const sll = sList.length;
    for (let sli = 0; sli < sll; ++sli) {
      const soektEiendom = sList[sli];
      // add it to inputParams to get statistics, whether isFarm or not
      const gList = soektEiendom.grunneiendomListe;
      const gll = gList.length;
      for (let gli = 0; gli < gll; ++gli) {
        const grunnEiendom = gList[gli];
        const gid = grunnEiendom.gid.split('-');
        inputParams.propertyIdType.push({
          bnr: gid[2],
          fnr: gid[3],
          gnr: gid[1],
          komm: gid[0],
        });
      }
    }
    return inputParams;
  }

  /**
   * Collect the statistics data for the farms in farmlist with the given type
   * @param { string } Type of gardskart
   * @param { any } farmList list of properties to collect data for
   * @param { boolean } flush  flush the cache and get fresh data
   * @return {[Observable]} [Return arealstatistikk]
   */
  getData(type: string, farmList: any, flush = false) {
    const farms = farmList.propertyIdType;
    if (!flush) {
      // check if data is in cache already
      const cached = this._statisticsCache.inCache(type, farms);
      if (cached) {
        return of(this._statisticsCache.get(type, farms));
      }
    }
    return this.#endPointService.fetchGardskart(farmList, this.getUrlStat(type)).pipe(
      map(data => {
        this._statisticsCache.add(type, farmList.propertyIdType, data);
        return data;
      }),
    );
  }

  /**
   * Will retreive geographical data for the specified farm.
   * @param  { string } Type of gardskart
   * @return {[observable]} [return gardskart vektor]
   */
  getPropertyGeo(gid: string, type: string) {
    const farmList = this.getPropertyInputParameters(gid);
    // console.log('Gårdskart layer for', type, gid, 'does not exist');
    // console.log('Send', farmList.propertyIdType);
    return this.#endPointService.fetchGardskart(farmList, this.getUrlGeo(type));
  }

  /**
   * Compute the url to use for retriving gardskart geodata for the given
   * gardskart-type
   * @param  {[string]} type
   * @return {[string]}      [return url to endpoint]
   */
  getUrlGeo(type) {
    let url = ApiConfig.proxiedBackendUrl + 'parcel_' + type + '_geojson';
    // Compute url for the gardskart-types that does not follow the
    // generic rule:
    switch (type) {
      case 'ar5kl7':
        url = ApiConfig.proxiedBackendUrl + 'parcel_ar5_7klasser_geojson';
        break;
      case 'ar5kl13':
        url = ApiConfig.proxiedBackendUrl + 'parcel_ar5_13klasser_geojson';
        break;
      default:
        break;
    }
    return url;
  }
  /**
   * Compute the url to use for retriving gardskart statistics data or the given
   * gardskart-type
   * @param  {[string]} type
   * @return {[string]}      [return url to endpoint]
   */
  getUrlStat(type) {
    let url = ApiConfig.proxiedBackendUrl + 'parcel_' + type + '_json';

    switch (type) {
      case 'ar5kl7':
        url = ApiConfig.proxiedBackendUrl + 'parcel_ar5_7klasser_json';
        break;
      case 'ar5kl13':
        url = ApiConfig.proxiedBackendUrl + 'parcel_ar5_13klasser_json';
        break;
      default:
        break;
    }
    return url;
  }

  getVisibleParcelList(id: string, layer?: BaseLayer): string[] {
    const type = this.gkType;
    const isFarm = this.#propertyService.getSoektEiendom().isFarm;
    const showAll = this.showAll;
    // cached statistics is saved for single farms
    const soektEiendom = [this.#propertyService.getEiendom(id)]; // as array of 1 element
    const farmList = this.generateFarmParameters(soektEiendom);
    const farms = farmList.propertyIdType;
    const cached = this._statisticsCache.inCache(type, farms);
    let stat = [];
    if (cached) {
      stat = this._statisticsCache.get(type, farms).jsonMapDoc;
    } else {
      console.log('There is no cache for this farm with current gk type');
    }

    // Build the list of subproperties/teigs that have been toggled off by user
    const hidden: string[] = [];
    this._addedProperties.list.forEach(parent => {
      parent.subProperties.forEach((subProperty: PropertyState) => {
        if (!subProperty.getVisibility()) {
          const prop = subProperty.getProperty();
          hidden.push(prop['komm'] + '-' + prop['gnr'] + '/' + prop['bnr'] + '/' + prop['fnr']);
        }
      });
    });

    // Build the list of parcel IDs to include
    const parcelIdList = [];
    stat.forEach(gStat => {
      // grunneiendom statistics
      gStat.teiglist.forEach(parcel => {
        const own = isFarm ? parcel.ownership : parcel.ownershipproperty;
        if ((ApiConfig.defaultOwners.indexOf(own) > -1 || showAll) && this._isParcelVisible(parcel, layer, hidden)) {
          parcelIdList.push(parcel.parcel_id);
        }
      });
    });
    return parcelIdList;
  }
}
