import { GkAction } from './index';

export const UPDATE_MAP_LAYER = 'UPDATE_MAP_LAYER';
export const RESET_MAP_LAYER = 'RESET_MAP_LAYER';

const DEFAULT = '';

export function mapLayerReducer(state: string = DEFAULT, action: GkAction) {
  switch (action.type) {
    case UPDATE_MAP_LAYER:
      return action.payload;

    case RESET_MAP_LAYER:
      return DEFAULT;

    default:
      return state;
  }
}
