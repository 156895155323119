import { inject, Injectable } from '@angular/core';
import { ErrorService } from '@services/error.service';
import { MapService } from '@services/map.service';
import { importedFileStyles } from '@tools/mapStyles';
import { MapConfig } from 'app/_config';
import GeoJSON from 'ol/format/GeoJSON';
import GPX from 'ol/format/GPX';
import KML from 'ol/format/KML';
import VectorLayer from 'ol/layer/Vector';
import Vector from 'ol/source/Vector';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export type ImportedFile = {
  checked?: boolean;
  id?: string;
  name?: string;
};

@Injectable()
export class ImportedFileMapService {
  private _files = new Subject<ImportedFile[]>();
  private addedFiles = new BehaviorSubject<boolean>(false);
  private dataStore: { files: ImportedFile[] } = { files: [] }; // store data in memory
  private errorService = inject(ErrorService);
  private fileFormats = {
    geojson: new GeoJSON(),
    gpx: new GPX(),
    kml: new KML(),
  };
  private mapService = inject(MapService);

  readonly files = this._files.asObservable();

  get hasAddedFiles$(): Observable<boolean> {
    return this.addedFiles.asObservable();
  }

  constructor() {
    this.mapService.dragAndDropInteraction.on('addfeatures', (event: { features: unknown; file: { name: string } }) => {
      const fileName = event?.file?.name;
      const fileFormat = fileName.split('.').pop().toLowerCase();
      if (this.fileFormats[fileFormat] === undefined) {
        this.errorService.putError('Feil fil format. Prøv følgende formatter: gpx, GeoJSON, kml.');
        return;
      }

      const map = this.mapService.getMap();
      const fileId = this.generateFileId(fileName);
      this.addToStore(fileName, fileId);
      this.makeAndAddFeature(map, event.features, fileFormat, fileId);
    });
  }

  /**
   * Receives file content, adds to Service Store and adds it to Map as feature
   * @param  {string} fileName
   * @param fileId
   */

  private addToStore(fileName: string, fileId: string) {
    this.dataStore.files.push({
      checked: true,
      id: fileId,
      name: this.generateFileName(fileName),
    });

    this._files.next(Object.assign({}, this.dataStore).files);
  }

  private generateFileId(fileName: string) {
    return `${fileName}-${Date.now()}-file`;
  }

  private generateFileName(fileName: string) {
    const newfileName = fileName.substr(0, fileName.lastIndexOf('.'));
    if (this.dataStore.files.filter(f => f.name === newfileName).length) {
      return `${newfileName} (kopi)`;
    }
    return `${newfileName}`;
  }

  private makeAndAddFeature(map: any, features: any, fileFormat: string, fileId: string) {
    const uploadedSource = new Vector({
      features: features,
    });

    const importedLayer = new VectorLayer({
      altitudeMode: 'clampToGround',
      fileFormat: fileFormat,
      id: fileId,
      legend: 'no',
      source: uploadedSource,
      style: importedFileStyles(fileFormat),
      type: 'imported-file',
      zIndex: 8,
    } as any);

    map.addLayer(importedLayer);
    map.getView().fit(uploadedSource.getExtent(), {
      duration: MapConfig.DURATION_DEFAULT,
      padding: MapConfig.EXTENT_PADDING,
      size: map.getSize(),
    });

    // Add overlay for imported saved drawings
    if (Array.isArray(features)) {
      features.forEach(f => this.mapService.rebuildFeatureOverlay(f, fileId));
    }

    this.addedFiles.next(true);
  }

  /**
   * Removes all entries in the Service Store
   */
  clearStore() {
    this.dataStore.files = [];
    this._files.next(Object.assign({}, this.dataStore).files);
  }

  readFile(fileFormat: string, fileName: string, fileContent: ArrayBuffer | string) {
    const map = this.mapService.getMap();
    const projection = map.getView().getProjection().getCode();

    const features = this.fileFormats[fileFormat.toLowerCase()].readFeatures(fileContent, {
      featureProjection: projection,
    });
    const fileId = this.generateFileId(fileName);
    this.makeAndAddFeature(map, features, fileFormat, fileId);
    this.addToStore(fileName, fileId);
  }

  /**
   * Removes file (layer) from the map and from the Service Store
   * @param {object} item
   */
  removeFileLayer(item: { id: string }) {
    this.dataStore.files = this.dataStore.files.filter(f => f.id !== item.id);
    this._files.next(Object.assign({}, this.dataStore).files);
    this.mapService.removeFileOverlays(item.id);
    this.mapService.removeLayer(item.id);
    if (this.dataStore.files.length < 1) {
      this.addedFiles.next(false);
    }
  }

  toggleFileLayer(item: { checked: boolean; id: string }) {
    this.dataStore.files.find(f => f.id === item.id).checked = item.checked;
    this.mapService.setVisible(item.id, item.checked, item.id);
  }
}
