import { Directive, ElementRef, EventEmitter, inject, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[gkDomChange]',
  standalone: true,
})
export class DomChangeDirective implements OnDestroy {
  private changes: MutationObserver;

  private elementRef = inject(ElementRef);

  @Output()
  domChange = new EventEmitter();

  constructor() {
    const element = this.elementRef.nativeElement;

    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => this.domChange.emit(mutation));
    });

    this.changes.observe(element, {
      attributes: true,
      characterData: true,
      childList: true,
      subtree: true,
    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
