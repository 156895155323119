import { GkAction } from './index';

// comment in code when we vil use cachede data for addedProperties too.

export const SHOW_ALERT_PROPERTY = 'SHOW_ALERT_PROPERTY';

const DEFAULT = '';

export function showAlertPropertyReducer(state: string = DEFAULT, action: GkAction) {
  switch (action.type) {
    case SHOW_ALERT_PROPERTY:
      return action.payload;
    default:
      return state;
  }
}
