<div class="loader" id="secondary-loader">
  <div class="center">
    <div class="inner-loader">
      <h1><ng-content /></h1>
      <div class="spinner-wave">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <button (click)="cancelRouting()" class="btn btn-light" type="button">Avbryt</button>
      <br /><br />
    </div>
  </div>
</div>
