import { addedPropertyReducer } from '@reducers/added-property.reducer';
import { backgroundReducer } from '@reducers/bg.reducer';
import { cancelReducer } from '@reducers/cancelGkRequests.reducer';
import { flushAddedPropertyReducer } from '@reducers/flushAddedProperty.reducer';
import { flushMainPropertyReducer } from '@reducers/flushMainProperty.reducer';
import { indicateAreaReducer } from '@reducers/indicateArea.reducer';
import { loadingReducer } from '@reducers/loading.reducer';
import { mapLayerReducer } from '@reducers/map-layer.reducer';
import { nullGeometryReducer } from '@reducers/null-geometry.reducer';
import { advancedPrintModeReducer } from '@reducers/print-mode.reducer';
import { propertyLevelReducer } from '@reducers/propertyLevel.reducer';
import { showAlertPropertyReducer } from '@reducers/showAlertProperty.reducer';
import { teigReducer } from '@reducers/teig.reducer';
import { activeToolReducer } from '@reducers/tool_state.reducer';

export const StoreSetup: any = {
  activeTool: activeToolReducer,
  addedProperties: addedPropertyReducer,
  advancedPrintMode: advancedPrintModeReducer,
  bgLayer: backgroundReducer,
  cancelGkRequests: cancelReducer,
  flushAddedProperty: flushAddedPropertyReducer,
  flushMainProperty: flushMainPropertyReducer,
  indicateArea: indicateAreaReducer,
  loading: loadingReducer,
  mapLayer: mapLayerReducer,
  nullGeometry: nullGeometryReducer,
  propertyLevel: propertyLevelReducer,
  showAlertProperty: showAlertPropertyReducer,
  showAllTeiger: teigReducer,
};
