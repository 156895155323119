// Angular modules
import { inject, Injectable } from '@angular/core';
// External modules
import { map, share } from 'rxjs/operators';

import { ApiConfig } from '../_config';
// Our modules
import { LayerTree } from '../_models/layer-tree';
import { ConfigService } from './config.service';
import { ErrorService } from './error.service';

@Injectable()
export class LayerTreeService {
  private _cs = inject(ConfigService);
  private config: any;

  private eService = inject(ErrorService);
  private url = `${ApiConfig.proxiedBackendUrl}configuration/otherlayers`;
  asyncThemeLayers: LayerTree;

  getAsyncThemeLayers() {
    return this._cs.getConfig(this.url).pipe(
      map(x => {
        if (!this.asyncThemeLayers) {
          this.config = x;
          this.asyncThemeLayers = new LayerTree(
            this.config.layers,
            this.config.layerGroups,
            this.config.otherLayerOrder,
            this.eService,
          );
        }
        return this.asyncThemeLayers;
      }),
      share(),
    );
  }
}
