import { inject, Injectable } from '@angular/core';
import { ApiConfig } from '@config/api.config';
import { MapConfig } from '@config/map.config';
import { PrintTools } from '@tools/print.tools';
import { nanoid } from 'nanoid';
import GeoJSON, { GeoJSONFeature } from 'ol/format/GeoJSON';
import Group from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import ImageWMS from 'ol/source/ImageWMS';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import sourceVector from 'ol/source/Vector';
import WMTS from 'ol/source/WMTS';
import StyleIcon from 'ol/style/Icon';

import { AreaStatisticsService } from './area-statistics.service';
import { BackgroundMapsService } from './background-maps.service';
import { PropertyService } from './property.service';

@Injectable()
export class PrintMapService {
  private bgMapService = inject(BackgroundMapsService);
  private encoders = {
    layers: {
      Group: g => {
        return { name: g.id, opacity: g.opacity != null ? g.opacity : 1.0 };
      },

      Layer: layer => {
        const p = layer.getProperties();
        return { name: p.id, opacity: p.opacity != null ? p.opacity : 1.0 };
      },

      Vector: (layer: VectorLayer<VectorSource>, resolution, proj) => {
        const layerName = layer.getProperties()['id'];
        const features = layer.getSource().getFeatures();
        const enc = this.encoders.layers['Layer'].call(this, layer);
        const format = new GeoJSON();
        const encFeatures = [];
        const encStyles = { version: '2' };

        // http://mapfish.github.io/mapfish-print-doc/styles.html
        features.forEach(feature => {
          if (feature.get('show') === false) {
            return;
          }
          // remove it to avoid mapfish error (Sometimes added as undefined, so remove it always)
          feature.unset('old_style', false);
          // https://github.com/ai/nanoid/
          const style_id = nanoid();
          const styleNameId = "[style_id = '" + style_id + "']";
          // Set style_id on feature
          feature.set('style_id', style_id);
          // feature to Geojson
          const encJSON: GeoJSONFeature = format.writeFeatureObject(feature);
          const z = PrintTools.getZindex(layer, feature, resolution);
          encJSON['zIndex'] = z;
          encJSON.properties['zIndex'] = z;
          if (z < 0) {
            encFeatures.push(encJSON);
          } else {
            const idx = PrintTools.getPosition(encFeatures, z);
            encFeatures.splice(idx, 0, encJSON);
          }
          // Get style from feature
          const sym = this.getTypeStyleArray(layer, feature, resolution);
          const style = { symbolizers: sym };

          if (feature.get('hasLabel') && feature.get('hasLabel') !== 'false') {
            const symText = PrintTools.makeLabel(feature);
            sym.push(symText);
          }
          // Mapfish demand ether a name who is connect with style_id on features property or a wildcard on style.
          // Connect style_id and style
          Object.assign(encStyles, { [styleNameId]: style });
        }); // end loop feature

        if (encFeatures.length === 0) {
          return null;
        } // "slett alle objekter" btn has been clicked
        const useWMS = true;
        if (useWMS && layerName.indexOf('GARDSKART') > -1) {
          // skip sending vector to MapFish
          return this.encodeGkAsWMS(layer, proj);
        }

        Object.assign(enc, {
          geoJson: {
            features: encFeatures.reverse(),
            type: 'FeatureCollection',
          },
          style: encStyles,
          type: 'geoJson',
        });
        return enc;
      }, // end wmts

      WMS: function (layer, proj) {
        const enc: any = {};
        const source = layer.source || layer.getSource();
        const params = source.getParams();
        let layers: any[];
        if (!(params.LAYERS instanceof Array)) {
          layers = params.LAYERS.split(',') || [];
        } else {
          layers = params.LAYERS;
        }
        const crs = proj.getCode();
        let wmsUrl: string;
        const ticket = params.TICKETID || '';
        wmsUrl = source.getUrl();

        if (
          wmsUrl.indexOf('http:') !== 0 &&
          wmsUrl.indexOf('https:') !== 0 &&
          wmsUrl.indexOf('HTTP:') !== 0 &&
          wmsUrl.indexOf('HTTPS:') !== 0
        ) {
          wmsUrl = 'https:' + wmsUrl;
        }

        const styles = [''];
        let filter = '';

        Object.assign(enc, {
          baseURL: wmsUrl,
          customParams: {
            CRS: crs,
            TICKETID: ticket,
            TRANSPARENT: 'true',
          },
          imageFormat: 'png',
          layers: layers,
          opacity: layer.opacity || layer.getOpacity(),
          styles: styles,
          type: 'WMS',
        });

        if (params.CQL_FILTER) {
          filter = params.CQL_FILTER;
          enc.customParams.CQL_FILTER = filter;
        }

        return enc;
      },

      WMTS: function (layer, proj) {
        const enc = {};
        const source = layer.getSource() || [];
        const matrixIds = source.getTileGrid().getMatrixIds();
        const matrices = PrintTools.makeMatriceForMapfish(matrixIds, source, proj);
        const baseUrl = source.urls[0].split('?')[0] || '';
        const token = source.urls[0].split('?')[1]?.split('=')[1] ? source.urls[0].split('?')[1].split('=')[1] : '';

        Object.assign(enc, {
          baseURL: baseUrl,
          customParams: token ? { gkt: token } : '',
          imageFormat: source.getFormat(),
          layer: source.getLayer(),
          matrices: matrices,
          matrixSet: source.getMatrixSet(),
          opacity: layer.opacity || layer.getOpacity(),
          requestEncoding: 'KVP',
          style: 'default',
          type: 'WMTS',
          version: '1.0.0',
        });
        return enc;
      },
    }, // end 'layers'
  }; // end encoders
  private gkWmsUrl: string;

  private propertyService = inject(PropertyService);

  private statService = inject(AreaStatisticsService);

  constructor() {
    this.gkWmsUrl = ApiConfig.gkWms;
  }

  /**
   * ask for a WMS instead of vector
   * @param  layer vector layer|
   * @param  proj  current projection
   * @return       encoded layer for mapFish
   */
  private encodeGkAsWMS(layer, proj) {
    const layerName = layer.getProperties().id;
    const crs = proj.getCode();

    let wmsLayerName = 'gardskart_';
    const gkType = PrintTools.getGkTypeCode(this.statService.gkType);
    const ortofoto = this.bgMapService.getSelected() === MapConfig.ORTHO;
    if (ortofoto) {
      wmsLayerName += 'ortofoto_';
    }
    wmsLayerName += gkType;

    const id = this.layer2farm(layerName);
    const parcelIds = this.statService.getVisibleParcelList(id, layer);

    return {
      baseURL: this.gkWmsUrl,
      customParams: {
        CRS: crs,
        DPI: '96',
        FORMAT_OPTIONS: 'dpi:96',
        MAP_RESOLUTION: '96',
        parcelids: parcelIds.join(','),
        TRANSPARENT: 'true',
      },
      imageFormat: 'image/png',
      layers: [wmsLayerName],
      opacity: layer.opacity || layer.getOpacity(),
      type: 'WMS',
    };
  }

  private encodeGroup(map, group) {
    const mainThis = this;
    const encs = [];
    const zs = [];
    const subLayers = group.layers.getArray();

    subLayers.forEach(subLayer => {
      if (subLayer.getVisible()) {
        const enc = mainThis.encoders.layers['Group'].call(this, group);
        const layerEnc = mainThis.encodeLayer(map, subLayer);

        if (layerEnc) {
          Object.assign(enc, layerEnc);
          encs.push(enc);
          zs.push(subLayer.getZIndex());
        }
      }
    });
    return { encs, zs };
  }

  private encodeLayer(myMap, layer): any {
    const myProj = myMap.getView().getProjection();
    let encLayer;
    const resolution = myMap.getView().getResolution();
    const encoders = this.encoders;
    const src = layer.getSource() || [];

    if (!(layer instanceof Group)) {
      let minResolution = 0;
      if (layer.minResolution >= 0) {
        minResolution = layer.minResolution;
      } else if (layer.getMinResolution() && layer.getMinResolution() >= 0) {
        minResolution = layer.getMinResolution();
      }
      let maxResolution = Infinity;
      if (layer.maxResolution >= 0) {
        maxResolution = layer.maxResolution;
      } else if (layer.getMaxResolution() && layer.getMaxResolution() >= 0) {
        maxResolution = layer.getMaxResolution();
      }
      if (resolution <= maxResolution && resolution >= minResolution) {
        if (src instanceof ImageWMS || src instanceof TileWMS) {
          encLayer = encoders.layers['WMS'].call(this, layer, myProj);
        }
        if (src instanceof sourceVector) {
          encLayer = encoders.layers['Vector'].call(this, layer, resolution, myProj);
        }
        if (src instanceof WMTS) {
          encLayer = encoders.layers['WMTS'].call(this, layer, myProj);
        }
      }
    }
    return encLayer;
  }

  // http://mapfish.github.io/mapfish-print-doc/styles.html#mapfishJsonParser
  private getTypeStyleArray(layer, feature, resolution): any[] {
    const isKMLFile = layer.getProperties().fileFormat === 'kml';
    const layerName = layer.getProperties().id;
    const drawings = layerName === 'drawVectorLayer' || layer.getProperties().savedDrawing;
    const geoType = feature.getGeometry().getType().toLowerCase();
    const isPoint = geoType === 'point';
    const isLinestring = geoType === 'linestring';
    const isPolygon = geoType === 'polygon';
    const tsa = [];
    let styles = drawings || isKMLFile ? feature.getStyle() : layer.getStyle();

    if (typeof styles === 'function') {
      styles = styles(feature, resolution);
    }
    if (!styles) {
      return tsa;
    }
    if (styles.constructor === Array) {
      styles = styles[0];
    }

    const fill = styles.getFill();
    const stroke = styles.getStroke();
    const img = styles.getImage();
    const isExternalIcon = img instanceof StyleIcon;

    const t = styles.getText();
    if (isPoint && img) {
      const pFill = drawings || isExternalIcon ? fill : img.getFill();
      const c = drawings ? PrintTools.str2rgb(fill.getColor()) : 'red';
      const pStroke = drawings || isExternalIcon ? stroke : img.getStroke();
      let pRadius: number;
      if (drawings || isExternalIcon) {
        if (drawings) {
          pRadius = 24 * img.getScale();
        } else {
          pRadius = 10;
        }
      } else {
        pRadius = img.getRadius();
      }
      const [rgb, a] = drawings || isExternalIcon ? [c, '1'] : PrintTools.splitRgba(pFill.getColor());
      const [rgbS, aS] = drawings || isExternalIcon ? [c, '1'] : PrintTools.splitRgba(pStroke.getColor());
      const width = drawings || isExternalIcon ? 1 : pStroke.getWidth();
      const point = {
        fillColor: rgb,
        graphicOpacity: a,
        pointRadius: pRadius,
        strokeColor: rgbS,
        strokeOpacity: aS,
        strokeWidth: width,
        type: 'point',
      };

      if (drawings || isExternalIcon) {
        const s = img.getSrc();

        if (drawings) {
          const publicUrlMapfishCanFindImg = ApiConfig.imageUrl;
          point['externalGraphic'] = `${publicUrlMapfishCanFindImg}${s.slice(1)}`;
        } else {
          point['externalGraphic'] = s;
        }
      }

      tsa.push(point);
    }
    if (stroke && !drawings) {
      const [rgbS, aS] = PrintTools.splitRgba(stroke.getColor());
      const line = {
        strokeColor: rgbS,
        strokeOpacity: aS,
        strokeWidth: stroke.getWidth(),
        type: 'line',
      };
      tsa.push(line);
      // Dashed line on top of border for added properties
      if (feature.get('added')) {
        const dashedLine = {
          strokeColor: 'rgb(255, 255, 255)',
          strokeDashstyle: 'dash',
          strokeOpacity: '0.9',
          strokeWidth: stroke.getWidth(),
          type: 'line',
        };
        tsa.push(dashedLine);
      }
    }
    if (drawings && isLinestring) {
      const rgba = img.getFill().getColor();
      const [rgb] = PrintTools.splitRgba(rgba);
      const line = {
        strokeColor: rgb,
        strokeOpacity: 0.9,
        strokeWidth: stroke.getWidth(),
        type: 'line',
      };
      tsa.push(line);
    }
    // KML files with line string seems to add in a polygon during print. Avoiding this through the check.
    if (fill && !drawings && !isLinestring) {
      const [rgb, a] = PrintTools.splitRgba(fill.getColor());
      const layerOpacity = layer.getOpacity(); // [a] is feature opacity
      const [rgbS, aS] = PrintTools.splitRgba(stroke.getColor());
      const polygon = {
        fillColor: rgb,
        fillOpacity: isKMLFile ? a : layerOpacity,
        strokeColor: rgbS,
        strokeOpacity: aS,
        strokeWidth: stroke.getWidth(),
        type: 'polygon',
      };

      tsa.push(polygon);
    }
    if (drawings && isPolygon) {
      const rgbaS = img.getFill().getColor();
      const [rgbS] = PrintTools.splitRgba(rgbaS);
      const [rgb, a] = fill ? PrintTools.splitRgba(fill.getColor()) : [rgbS, 0];
      const polygon = {
        fillColor: rgb,
        fillOpacity: a,
        strokeColor: rgbS,
        strokeOpacity: 0.9,
        strokeWidth: stroke.getWidth(),
        type: 'polygon',
      };
      tsa.push(polygon);
    }
    if (t && t.getText()) {
      const alignment = {
        bottom: 'cb',
        left: 'lm',
        right: 'rm',
      };

      const f = t.getFont().split(' ');
      const sc = t.getStroke() ? t.getStroke().getColor() : 'rgba(255,255,255,0)';
      const sw = t.getStroke() ? t.getStroke().getWidth() : 1;
      const align = alignment[t.getTextAlign()] || 'cm';
      const label = t.getText().replace(/'/g, '´');
      const fontSize = parseInt(f[2].slice(0, 2), 10) - 3 || 12;

      // For KML files
      const hc = t.getStroke() ? t.getStroke().getColor() : '#ffffff';
      const haloColorConverted = PrintTools.getConvertedColor(hc);

      const text = {
        fontColor: isKMLFile ? PrintTools.getConvertedColor(t.getFill().getColor()) : t.getFill().getColor(),
        fontSize: `${fontSize}px`,
        fontStyle: f[1],
        fontWeight: f[0],
        haloColor: isKMLFile ? haloColorConverted : sc,
        haloOpacity: '0.7',
        haloRadius: sw / 3,
        label: label,
        labelAlign: align,
        labelXOffset: t.getOffsetX(),
        type: 'text',
      };
      if (tsa.length === 0) {
        // GARDSBRUKSNUMMER, for GARDSKART text we use a style name that is based on areal (getTextStyle)
        tsa.push(text);
      }

      if (isKMLFile) {
        tsa.push(text);
      }
    }

    return tsa;
  }

  /**
   * Use it in case you know the name of the layer, but not its property id
   * @param  lid layer id/name
   * @return     farm id
   */
  private layer2farm(lid: string): string {
    const s = lid.split('_');
    if (s.length > 2) {
      // added farm
      return s[2];
    } else {
      // first searched farm
      return this.propertyService.getSoektEiendom().getReadableSoektGid();
    }
  }
  // Check mapfish spec for style here:
  getLayersForMapfish(map: any) {
    let mapFishLayers = [];
    // liste med index på layer
    let zs = [];
    const mainThis = this;
    const olLayers = map.getLayers().getArray();
    olLayers.forEach(olLayer => {
      const prop = olLayer.getProperties();
      const visible = olLayer.getVisible();
      if (!visible || prop.id === 'printBox' || prop.id === 'ingen') {
        return;
      }
      // not print printbox or unviible layers
      // get backgroundolLayers and vectors (imorted files and drawings)
      if (!(olLayer instanceof Group)) {
        const mapFishLayer = mainThis.encodeLayer(map, olLayer);
        if (mapFishLayer) {
          mapFishLayers.push(mapFishLayer);
          zs.push(olLayer.getZIndex());
        }
      } else {
        // otherLayers
        const otherLayers = olLayer.getLayers();
        otherLayers.forEach(wmsLayer => {
          if (wmsLayer.getVisible()) {
            if (wmsLayer instanceof Group) {
              const encsWithZs = mainThis.encodeGroup(map, wmsLayer.getProperties());
              mapFishLayers = mapFishLayers.concat(encsWithZs.encs);
              zs = zs.concat(encsWithZs.zs);
            } else {
              const enc = mainThis.encodeLayer(map, wmsLayer);
              if (enc) {
                mapFishLayers.push(enc);
                zs.push(wmsLayer.getZIndex());
              }
            }
          }
        });
      }
    });
    return PrintTools.reorderAccordingToZindex(mapFishLayers, zs);
  }

  // not in use, keep maybe we vill use this later
  // private getTextStyle(layer, feature, resolution): Array<any> {
  //   const p = feature.getProperties();
  //   const tsa = [];
  //   let styles = layer.getStyle();
  //   let iconStyle = null;
  //   if (typeof styles === 'function') {
  //     styles = styles(feature, resolution);
  //   }
  //   if (!styles) { return tsa; }
  //   if (styles.constructor === Array) {
  //     if (styles.length > 1) { iconStyle = styles[1]; }
  //     styles = styles[0];
  //   };
  //
  //   const t = styles.getText();
  //   if (t) {
  //     const f = t.getFont().split(' ');
  //     const sc = (t.getStroke()) ? t.getStroke().getColor() : 'rgba(255,255,255,1)';
  //     const sw = (t.getStroke()) ? t.getStroke().getWidth() : 0;
  //     const align = (t.getTextAlign() === 'bottom') ? 'cb' : 'cm';
  //     const label = t.getText().replace(/'/g, '´');
  //     const text = {
  //       type: 'text',
  //       fontColor: t.getFill().getColor(),
  //       fontSize: (parseInt(f[2].slice(0, 2), 10) - 3) + 'px',
  //       fontStyle: f[1],
  //       fontWeight: f[0],
  //       haloColor: sc,
  //       haloRadius: sw,
  //       label: label,
  //       labelAlign: align,
  //       labelYOffset: '-' + (t.getOffsetY() + 1).toString()
  //     };
  //     tsa.push(text);
  //   }
  //   if (iconStyle) {
  //     const img = iconStyle.getImage();
  //     if (!img) { return tsa; }
  //     const pRadius = 16 * img.getScale();
  //     const s = img.getSrc();
  //     const publicUrlMapfishCanFindImg = ApiConfig.imageUrl;
  //     const point = {
  //       type: 'point',
  //       externalGraphic: publicUrlMapfishCanFindImg + s.slice(1),
  //       pointRadius: pRadius
  //     };
  //     tsa.push(point);
  //   }
  //
  //   return tsa; // [{textStyle}, {point/symbol style}]
  // }
}
