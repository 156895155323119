import { EventEmitter, inject, Injectable, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { PRINT_STATE, STANDARD_STATE, StoreStates } from '../_reducers';

@Injectable()
export class PanelService {
  private store = inject<Store<StoreStates>>(Store);

  @Output() bugPanel = new EventEmitter<boolean>(true);
  @Output() bugPanelSubmit = new EventEmitter<boolean>(true);
  @Output() leftPanel = new EventEmitter<boolean>(true);
  @Output() printPanel = new EventEmitter<boolean>(true);
  @Output() printPanelFinished = new EventEmitter<boolean>(true);
  @Output() printPanelSubmit = new EventEmitter<boolean>(true);

  // bugPanel
  setBugPanel(state: boolean) {
    this.bugPanel.emit(state);
  }

  // bugPanelSubmit
  setBugPanelSubmit(state: boolean) {
    this.bugPanelSubmit.emit(state);
  }

  // leftPanel
  setLeftPanel(state: boolean) {
    this.leftPanel.emit(state);
  }

  // printPanel
  setPrintPanel(state: boolean) {
    this.printPanel.emit(state);
    if (state) {
      this.store.dispatch({ type: PRINT_STATE });
    }
  }

  // printPanelFinished
  setPrintPanelFinished(state: boolean) {
    this.printPanelFinished.emit(state);
    this.store.dispatch({ type: STANDARD_STATE });
  }

  // printPanelSubmit
  setPrintPanelSubmit(state: boolean) {
    this.printPanelSubmit.emit(state);
  }
}
