import { Action } from '@ngrx/store';

export const CANCEL_GK_REQUESTS = 'CANCEL_GK_REQUESTS';
export const RESETCOUNTER = 'RESETCOUNTER';

export function cancelReducer(state = 0, action: Action) {
  switch (action.type) {
    case CANCEL_GK_REQUESTS:
      return state + 1;
    case RESETCOUNTER:
      return 0;
    default:
      return state;
  }
}
