import { GkAction } from './index';

export const SHOW_ALL_TEIGER = 'SHOW_ALL_TEIGER';

const DEFAULT = false;

export function teigReducer(state: boolean = DEFAULT, action: GkAction) {
  switch (action.type) {
    case SHOW_ALL_TEIGER:
      return action.payload;

    default:
      return state;
  }
}
