import { Action } from '@ngrx/store';

export const ADVANCED_PRINT_MODE = 'ADVANCED_PRINT_MODE';
export const STANDARD_PRINT_MODE = 'STANDARD_PRINT_MODE';
export const RESET = 'RESET';

const DEFAULT = false;

export function advancedPrintModeReducer(state: boolean = DEFAULT, action: Action) {
  switch (action.type) {
    case ADVANCED_PRINT_MODE:
      return true;

    case STANDARD_PRINT_MODE:
      return false;

    case RESET:
      return DEFAULT;

    default:
      return state;
  }
}
