import { inject, Injectable } from '@angular/core';
import { Property, PropertyData, SoektEiendom } from '@models/property';
import { select, Store } from '@ngrx/store';
import { MapService } from '@services/map.service';
import { PropertyService } from '@services/property.service';
import { MapTools } from '@tools/map.tools';
import GeoJSON from 'ol/format/GeoJSON';
import { forkJoin, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiConfig, MapConfig } from '../_config';
import { StoreStates } from '../_reducers';
import { EndPointService } from './endpoint.service';
import { ErrorService } from './error.service';

@Injectable()
export class GaardskartService {
  private endPointService = inject(EndPointService);
  private error = inject(ErrorService);
  private mapService = inject(MapService);
  private propertyService = inject(PropertyService);
  private showAll: boolean;

  private showAllTeiger: Observable<boolean>;
  private store = inject<Store<StoreStates>>(Store);

  constructor() {
    const store = this.store;

    this.showAllTeiger = store.pipe(select('showAllTeiger'));
    this.showAllTeiger.subscribe(showAllTeiger => {
      this.showAll = showAllTeiger;
    });
  }

  /**
   * [chech4geometry Check if respons have some feature with geometry,
   * if it have, then we show data. Alle teiger without geometry will be
   * filter away later]
   * @param  geoObjects features
   * @return            true if have geometry else return false
   */
  private chech4geometry(geoObjects: any) {
    let isGeometry = false;
    for (const features of geoObjects) {
      if (features.geometry !== null) {
        isGeometry = true;
      }
    }
    return isGeometry;
  }

  /**
   * Clearing the model i PropertyService, removing eiendomsgrenser,
   * gardsbruksnummer and driftsenter for searched property
   */
  private clearGaardskartMap(): void {
    // do some tidying up from eventual previous searches
    this.propertyService.clearModel();
    // remove eiendomsgrenser
    // remove gBnrLayer
    // remove driftsenterLayer
    this.mapService.removeLayer(MapConfig.EIENDOMSGRENSE);
    this.mapService.removeLayer(MapConfig.GARDSBRUKSNUMMER);
    this.mapService.removeLayer(MapConfig.DRIFTSSENTER);
  }

  /**
   * Validates the data response from endpoint service
   *
   * @param  {any}    data to be validated
   * @return {any}         data is returned if everything is valid,
   *                       otherwise an error is thrown
   */
  private dataValidator(data: any): any {
    if (this.endPointService.isRequestCancelled()) {
      return data;
    }
    let haveGeometry;
    for (const element of data) {
      if (Object.keys(element.jsonMapDoc).length === 0 && element.jsonMapDoc.constructor === Object) {
        throw new Error('Kan ikke lage kart for denne eiendommen.');
      }
      if (element.jsonMapDoc.features === null) {
        throw new Error('Kan ikke lage kart for denne eiendommen.');
      }
      haveGeometry = this.chech4geometry(element.jsonMapDoc.features);
      if (Object.keys(element.jsonMapDoc).length === 2 && element.jsonMapDoc.constructor === Object && !haveGeometry) {
        throw new Error(
          'Vi kan dessverre ikke lage kart for denne eiendommen, fordi matrikkelen har vært eller er utilgjengelig.' +
            ' Vennligst prøv igjen om noen minutter.',
        );
      }
    }
    return data;
  }

  /**
   * Extract properties from response from endpoint service
   * @param  {Array<any>}          data  data entries
   * @param  {Array<any>}          farms farm entries
   * @return {Array<PropertyData>}       array of property data generated by the input
   */
  private extractPropertyData(data: any[], farms: any[]): PropertyData[] {
    const propertiesData: PropertyData[] = [];

    for (let i = 0; i < data.length; i++) {
      propertiesData.push(
        new PropertyData({
          data: data[i],
          farms: farms[i],
        }),
      );
    }

    return propertiesData;
  }

  /**
   * Generate the fallback endpoint URL with the given parameteres
   *
   * @param  {string} komm
   * @param  {string} gnr
   * @param  {string} bnr
   * @param  {string} fnr
   * @return {string} url the fallback-enpoint in backend when searching for given farm
   */
  private getFallbackUrl(komm: string, gnr: string, bnr: string, fnr: string): string {
    const apiUrl = ApiConfig.property_status;
    return apiUrl + komm + '/' + gnr + '/' + bnr + '/' + fnr;
  }

  /**
   * Generate the main endpoint URL with the given parameteres
   *
   * @param  {string} komm
   * @param  {string} gnr
   * @param  {string} bnr
   * @param  {string} fnr
   * @return {string} url to use for initiating serch for a farm
   */
  private getFarmSerchUrl(komm: string, gnr: string, bnr: string, fnr: string): string {
    const apiUrl = ApiConfig.property_request;
    return apiUrl + komm + '/' + gnr + '/' + bnr + '/' + fnr;
  }

  /**
   * Proccess the property data, and add it to the map
   *
   * @param  {PropertyData} propertyData        property data to be added
   * @param  {boolean = false} initialProperty  whether or not it is the initial property
   *
   * @return {boolean}                          whether or not the operation was successful
   */
  private processPropertyData(propertyData: PropertyData, initialProperty = false): boolean {
    const jsonMapDoc = propertyData.data.jsonMapDoc;

    // here propertyData.farms is not ready
    const propertySuccess = this.propertyService.addPropertyToModel(propertyData, !initialProperty);
    // here propertyData.farms is ready

    if (propertySuccess) {
      const id = initialProperty ? '' : '_' + propertyData.farms.getReadableHnr();
      const dataSrid = propertyData.data.srid;

      const mapSrid = this.mapService.getSRID();
      const dataProj = 'EPSG:' + dataSrid;
      const mapProj = 'EPSG:' + mapSrid;

      const isFarm = this.propertyService.getSoektEiendom().isFarm;

      const format = new GeoJSON({
        dataProjection: dataProj,
        featureProjection: mapProj,
      });
      const propertyFeatures = format.readFeatures(jsonMapDoc);
      for (let i = propertyFeatures.length - 1; i >= 0; --i) {
        // in reverse
        if (propertyFeatures[i].getGeometry() === null) {
          const null_props = propertyFeatures[i].getProperties();
          const null_grunneiendom =
            null_props['municipality_nr'] + '-' + null_props['gnr'] + '/' + null_props['bnr'] + '/' + null_props['fnr'];
          const payload = { farm: null_grunneiendom, type: 'UPDATE_NULL_GEOMETRY_STATE' };
          this.store.dispatch({ payload: payload, type: 'UPDATE_NULL_GEOMETRY_STATE' });
          propertyFeatures.splice(i, 1);
        }
      }
      const borderLayer = MapTools.createBorderLayer(propertyFeatures, isFarm, this.showAll, initialProperty);

      // const borderLayer = MapTools.createBorderLayer(propertyFeatures, isFarm, this.showAll);
      const gBnrLayer = MapTools.createGBnrLayer(propertyFeatures, this.showAll);

      if (borderLayer !== null) {
        // This will add the border layer to the map.
        // map will configure itself when it is initialized..
        this.mapService.addPrematureOrLayer(MapConfig.EIENDOMSGRENSE + id, borderLayer);
      } else {
        this.error.putError('Kan ikke vise eiendomsgrense.');
      }

      if (gBnrLayer !== null) {
        if (!initialProperty) {
          const gbnrCheckbox = document.getElementById('showGbnr') as HTMLInputElement;
          gBnrLayer.setOpacity(gbnrCheckbox.checked ? 1 : 0);
        }

        this.mapService.addPrematureOrLayer(MapConfig.GARDSBRUKSNUMMER + id, gBnrLayer);
      } else {
        this.error.putError('Kan ikke vise gårds- og bruksnr.');
      }

      const driftsenterLayer = MapTools.createDriftsenterLayer(
        MapConfig.DRIFTSSENTER + id,
        jsonMapDoc,
        dataProj,
        mapProj,
      );
      this.propertyService.triggerDriftsenter();
      if (driftsenterLayer !== null) {
        this.mapService.increaseDsNumber();
        // avoid multiple driftssenter legends
        if (!initialProperty) {
          driftsenterLayer.set('legend', 'no');
          const dsCheckbox = document.getElementById('showDs') as HTMLInputElement;
          driftsenterLayer.setOpacity(dsCheckbox.checked ? 1 : 0);
          dsCheckbox.disabled = false; // in case it was true due to initialProperty without ds
        }
        // add driftssenter layer
        this.mapService.addPrematureOrLayer(MapConfig.DRIFTSSENTER + id, driftsenterLayer);
      }
    }

    // this.mapService.printMapLayerIds();
    return propertySuccess;
  }

  /**
   * Perform a search on 'grunneiendom'
   *
   * @param {Array<Property>} properties The route parameters (komm, gnr, bnr, fnr)
   * @param {Array<string>} states Array of datastates to accept, ie 'OK', 'OLD_DATA' etc.
   * @param {boolean} verified Request only verified data
   */
  private searchGrunneiendom(properties: Property[], states: string[], verified = false): Observable<PropertyData[]> {
    let grunneiendomEndpointUrl: string;
    if (verified) {
      // We only want to request the status-endpoint in order to get
      // verified data. Hence fallbackUrl and endpointUrl will be the same.
      grunneiendomEndpointUrl = ApiConfig.matrikkel_property_status;
    } else {
      grunneiendomEndpointUrl = ApiConfig.matrikkel_property_request;
    }
    const grunneiendomFallbackUrl = ApiConfig.matrikkel_property_status;

    const requestBody = {
      maxAge: ApiConfig.max_age,
      propertyIdType: [],
      usersessionIdType: this.endPointService.sessionId,
    };

    for (const property of properties) {
      requestBody.propertyIdType.push({
        bnr: property.bnr,
        fnr: property.fnr,
        gnr: property.gnr,
        komm: property.komm,
      });
    }

    const searchedProperties = [];
    searchedProperties.push(
      new SoektEiendom(
        properties[0].komm + '-' + properties[0].gnr + '-' + properties[0].bnr + '-' + properties[0].fnr,
      ),
    );

    return this.endPointService.postData(grunneiendomEndpointUrl, requestBody, grunneiendomFallbackUrl, states).pipe(
      map(data => {
        data.jsonMapDoc = JSON.parse(data.jsonMapDoc);
        return data;
      }),
      map(data => (data = [data])),
      map(data => this.dataValidator(data)),
      map(data => this.extractPropertyData(data, searchedProperties)),
      catchError(error => {
        this.error.putError(error.message ? error.message : error.toString());
        return observableThrowError(new Error(error.message ? error.message : error.toString()));
      }),
    );
  }

  /**
   * Perform a search on 'landbrukseiendom'
   *
   * @param {Array<Property>} properties The route parameters (komm, gnr, bnr, fnr)
   * @param {Array<string>} states Array of datastates to accept, ie 'OK', 'OLD_DATA' etc.
   * @param {boolean} verified Request only verified data
   */
  private searchLandbrukseiendom(
    properties: Property[],
    states: string[],
    verified = false,
  ): Observable<PropertyData[]> {
    const searchedProperties: SoektEiendom[] = [];
    const propertyRequests: Observable<any>[] = [];

    // add all the properties in the route to arrays for processing
    if (properties !== null) {
      for (const property of properties) {
        searchedProperties.push(
          new SoektEiendom(property.komm + '-' + property.gnr + '-' + property.bnr + '-' + property.fnr),
        );
        let endpointUrl: string;
        const fallbackUrl = this.getFallbackUrl(property.komm, property.gnr, property.bnr, property.fnr);
        if (verified) {
          // We only want to request the status-endpoint in order to get
          // verified data. Hence fallbackUrl and endpointUrl will be the same.
          endpointUrl = fallbackUrl;
        } else {
          endpointUrl = this.getFarmSerchUrl(property.komm, property.gnr, property.bnr, property.fnr);
        }
        propertyRequests.push(this.endPointService.getData(endpointUrl, fallbackUrl, states));
      }
    }

    // perform a forkjoin, which make simultaneous requests (based on request specified)
    return forkJoin(propertyRequests).pipe(
      map(data => this.dataValidator(data)),
      map(data => this.extractPropertyData(data, searchedProperties)),
      catchError(error => {
        this.error.putError(error.message ? error.message : error.toString());
        return observableThrowError(new Error(error.message ? error.message : error.toString()));
      }),
    );
  }

  /**
   * Add multiple properties to the map
   *
   * @param {Array<PropertyData>} properties to be added to the map
   */
  addProperties(properties: PropertyData[]): void {
    if (properties === null || properties === undefined) {
      return;
    }
    for (const property of properties) {
      this.processPropertyData(property);
    }
  }

  /**
   * Add property to the map
   *
   * @param  {PropertyData} property to be added to the map
   * @return {boolean}               whether or not the operation was successful
   */
  addProperty(property: PropertyData): boolean {
    if (property === undefined || !property) {
      return false;
    }
    return this.processPropertyData(property);
  }

  /**
   * Get multiple properties data from endpoint service
   *
   * @param  {Array<Property>} properties     to fetch data for
   * @param  {boolean = false} grunneiendom   whether or not it is a 'grunneiendom'
   * @param  {Array<string>}   states         return data of these states ie. 'OK' , 'OLD_DATA' etc   *
   * @return {Observable}                     parsed response from endpoint service
   */
  getProperties(properties: Property[], grunneiendom = false, states: string[]): Observable<PropertyData[]> {
    if (grunneiendom) {
      return this.searchGrunneiendom(properties, states);
    } else {
      return this.searchLandbrukseiendom(properties, states);
    }
  }

  /**
   * Get property data from endpoint service
   *
   * @param  {Property} property     to fetch data for
   * @param  {Array<string>}   states         return data of these states ie. 'OK' , 'OLD_DATA' etc
   * @param  {boolean = false} grunneiendom   whether or not it is a 'grunneiendom'
   * @return {Observable}                     parsed response from endpoint service
   */
  getProperty(property: Property, states: string[], grunneiendom = false): Observable<PropertyData> {
    if (grunneiendom) {
      return this.searchGrunneiendom([property], states).pipe(map(data => data[0]));
    } else {
      return this.searchLandbrukseiendom([property], states).pipe(map(data => data[0]));
    }
  }

  /**
   * Get multiple verified  properties data from endpoint service
   *
   * @param  {Array<Property>} properties     to fetch data for
   * @param  {boolean = false} grunneiendom   whether or not it is a 'grunneiendom'
   * @param  {Array<string>}   states         return data of these states ie. 'OK' , 'OLD_DATA' etc   *
   * @return {Observable}                     parsed response from endpoint service
   */
  getVerifiedProperties(properties: Property[], grunneiendom = false, states: string[]): Observable<PropertyData[]> {
    const verified = true;
    if (grunneiendom) {
      return this.searchGrunneiendom(properties, states, verified);
    } else {
      return this.searchLandbrukseiendom(properties, states, verified);
    }
  }
  /**
   * Get property data from endpoint service
   *
   * @param  {Property} property     to fetch data for
   * @param  {boolean = false} grunneiendom   whether or not it is a 'grunneiendom'
   * @param  {Array<string>}   states         return data of these states ie. 'OK' , 'OLD_DATA' etc
   * @return {Observable}                     parsed response from endpoint service
   */
  getVerifiedProperty(property: Property, states: string[], grunneiendom = false): Observable<PropertyData> {
    const verified = true;
    if (grunneiendom) {
      return this.searchGrunneiendom([property], states, verified).pipe(map(data => data[0]));
    } else {
      return this.searchLandbrukseiendom([property], states, verified).pipe(map(data => data[0]));
    }
  }

  /**
   * Initialize the map
   *
   * @param {Array<PropertyData>} properties properties to initialize the map with
   */
  initGaardskartMap(properties: PropertyData[]): void {
    if (properties === null || properties === undefined) {
      return;
    }

    this.clearGaardskartMap();
    for (const property of properties) {
      this.processPropertyData(property, true);
    }
  }
}
